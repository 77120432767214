<template>
    <div id="About" class="simple-wrap style2 pb-100 bg-stratos">
        <img src="../../assets/images/section-shape-2.png" alt="Image" class="section-shape-two">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6 col-12">
                    <div class="simple-img-wrap">
                        <img src="../../assets/images/about/simple-shape-1.png" alt="Image" class="simple-shape-one bounce">
                        <img src="../../assets/images/about/simple-shape-2.png" alt="Image" class="simple-shape-two moveHorizontal">
                        <img src="../../assets/images/about/simple-img-2.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="simple-content">
                        <img src="../../assets/images/about/simple-shape-3.png" alt="Image" class="simple-shape-three bounce">
                        <div class="content-title style2">
                            <span>About Us</span>
                            <h2>We Do Our Best To Keep Customer's Money Safe</h2>
                            <p> 
                                A leading provider of cutting-edge payment processing solutions. At NORAH, we are dedicated to revolutionizing the way businesses and individuals to honour their payments. Our mission is to empower you with secure, efficient, and seamless payment experiences, whether you're a small startup or a global enterprise. We simplify payment processing while maintaining the highest standards of security.  
                            </p>
                        </div>
                        <ul class="content-feature-list list-style">
                            <li><i class="ri-checkbox-multiple-fill"></i>Security</li>
                            <li><i class="ri-checkbox-multiple-fill"></i>Reliability</li>
                            <li><i class="ri-checkbox-multiple-fill"></i>User-Friendly</li>
                            <li><i class="ri-checkbox-multiple-fill"></i>Compatibility</li>
                            <li><i class="ri-checkbox-multiple-fill"></i>Transparent Pricing</li>
                            <li><i class="ri-checkbox-multiple-fill"></i>Customer Support</li>
                        </ul>
                        <!-- <router-link to="/register" class="btn style1">Create Account</router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProtectMoney'
}
</script>