<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">
                    <div class="single-terms">
                        <h3 style="color: aliceblue;">Introduction</h3>
                        <p style="color: rgb(244, 244, 244);">
                            Norah, and its affiliates ("Norah," "we," "our," or "us") are committed to respecting and protecting the privacy of users in Zimbabwe. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our payment processing platform, website, and related services (collectively, the "Services"). By using our Services in Zimbabwe, you consent to the practices described in this Privacy Policy.   
                         </p>
                        
                    </div>
            
                    <div class="single-terms">
                        <h2 style="color: aliceblue;">2. Information We Collect</h2>
                        <p style="color: rgb(244, 244, 244);">We collect various types of information, including: </p>
                        <h5 style="color: aliceblue;">2.1 Personal Information</h5>
                        <ol>
                            <li style="color: rgb(244, 244, 244);">Full Names.</li>
                            <li style="color: rgb(244, 244, 244);">Email addresses.</li>
                            <li style="color: rgb(244, 244, 244);">Phone Numbers.</li>
                            <li style="color: rgb(244, 244, 244);">Postal addresses.</li>
                        </ol>
                        <h5 style="color: aliceblue;">2.2 Payment Information</h5>
                        <p style="color: rgb(244, 244, 244);">Norah processes payment transactions, which may involve collecting and storing financial data such as: </p>
                        <ol >
                            <li style="color: rgb(244, 244, 244);">Credit card details.</li>
                            <li style="color: rgb(244, 244, 244);">Bank account information.</li>
                        </ol>
                        <h5 style="color: aliceblue;">2.3 Business Information</h5>
                        <p style="color: rgb(244, 244, 244);">If you use Norah for business purposes in Zimbabwe, we may collect data related to your business, including: </p>
                        <ol>
                            <li style="color: rgb(244, 244, 244);">Tax identification numbers.</li>
                            <li style="color: rgb(244, 244, 244);">Business addresses.</li>
                            <li style="color: rgb(244, 244, 244);">Business contact information.</li>
                        </ol>
                        <h5 style="color: aliceblue;">2.4 Usage Information</h5>
                        <p style="color: rgb(244, 244, 244);">We collect information about how you interact with our platform, including: </p>
                        <ol>
                            <li style="color: rgb(244, 244, 244);">IP address.</li>
                            <li style="color: rgb(244, 244, 244);">Device information.</li>
                            <li style="color: rgb(244, 244, 244);">Browser type.</li>
                            <li style="color: rgb(244, 244, 244);">Pages viewed.</li>
                            <li style="color: rgb(244, 244, 244);">IP address.</li>
                            <li style="color: rgb(244, 244, 244);">Referring pages.</li>
                        </ol>
                        <h5 style="color: aliceblue;">Communication: We may keep records of your interactions with our support team and email communications.</h5>
                    </div>

                    <div class="single-terms">
                        <h2 style="color: aliceblue;">3. How We Use Your Information</h2>
                        <p style="color: rgb(244, 244, 244);">We use the information we collect for various purposes, including: </p>
                        <h5 style="color: aliceblue;">3.1 Payment Processing</h5>
                        <p style="color: rgb(244, 244, 244);">We use payment information to facilitate transactions and provide payment processing services.</p>
                        
                        <h5 style="color: aliceblue;">3.2 Fraud Prevention</h5>
                        <p style="color: rgb(244, 244, 244);">Norah employs advanced security measures to detect and prevent fraudulent activities, ensuring secure transactions in Zimbabwe.</p>
                        
                        <h5 style="color: aliceblue;">3.3 Customer Support</h5>
                        <p style="color: rgb(244, 244, 244);">We may use your information to provide customer support, respond to inquiries, and address issues related to our Services.</p>
                        
                        <h5 style="color: aliceblue;">3.4 Legal Compliance</h5>
                        <p style="color: rgb(244, 244, 244);">Norah complies with legal obligations and may share information with law enforcement, regulators, or other third parties as required under Zimbabwean law.</p>
                        
                        <h5 style="color: aliceblue;">3.5 Service Improvement</h5>
                        <p style="color: rgb(244, 244, 244);">We use data to enhance our services, develop new features, and analyze usage patterns, all while respecting Zimbabwean privacy laws.</p>
                        
                    </div>

                    <div class="single-terms">
                        <h2 style="color: aliceblue;">4. Data Sharing</h2>
                        <p style="color: rgb(244, 244, 244);">Norah may share your information with third parties under specific circumstances: </p>
                        <h5 style="color: aliceblue;">4.1 Service Providers</h5>
                        <p style="color: rgb(244, 244, 244);">We may share data with service providers that help us deliver our services in Zimbabwe, subject to confidentiality obligations.</p>
                        
                        <h5 style="color: aliceblue;">4.2 Business Partners</h5>
                        <p style="color: rgb(244, 244, 244);">:Norah may share information with business partners in Zimbabwe, but only when necessary for the services they provide</p>
                        
                        <h5 style="color: aliceblue;">4.3 Legal Compliance</h5>
                        <p style="color: rgb(244, 244, 244);">We may disclose data to comply with legal obligations or in response to legal requests under Zimbabwean law..</p>
        
                    </div>
                    
                    <div class="single-terms">
                        <h3 style="color: aliceblue;">5. Data Security</h3>
                        <p style="color: rgb(244, 244, 244);">Norah is committed to safeguarding user data in Zimbabwe. We use encryption and security measures to protect data from unauthorized access, disclosure, alteration, or destruction, in accordance with Zimbabwean privacy standards.</p>
                    </div>
                    <div class="single-terms">
                        <h3 style="color: aliceblue;">6. User Control</h3>
                        <p style="color: rgb(244, 244, 244);">Norah provides users in Zimbabwe with options to access, modify, or delete their personal information. Users can also opt-out of certain communications.</p>
                    </div>
                    <div class="single-terms">
                        <h3 style="color: aliceblue;">7. Cookies and Tracking</h3>
                        <p style="color: rgb(244, 244, 244);">Norah uses cookies and similar technologies in Zimbabwe for various purposes, including analytics and improving the user experience. Users in Zimbabwe may have the option to manage cookie preferences.</p>
                    </div>
                    <div class="single-terms">
                        <h3 style="color: aliceblue;">8. International Data Transfers</h3>
                        <p style="color: rgb(244, 244, 244);">Norah may transfer data internationally, including to servers located outside Zimbabwe, and ensures appropriate safeguards are in place to protect the data, in accordance with applicable Zimbabwean laws.</p>
                    </div>
                    <div class="single-terms">
                        <h3 style="color: aliceblue;">9. Changes to the Privacy Policy</h3>
                        <p style="color: rgb(244, 244, 244);">Norah may update its privacy policy, and users in Zimbabwe are encouraged to review it periodically for any changes, taking into account local regulations.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>